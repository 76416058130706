import * as $ from 'jquery';
import Glide from '@glidejs/glide';

// Javascript to be fired on every page
$(document).ready(function () {
  $('.menu-icon').click(function(){
    $('.nav-screen').toggleClass('nav-show z-30');
    $(".menu-icon").fadeOut(function () {
        $(".menu-icon").text(($(".menu-icon").text() == 'close') ? 'menu' : 'close').fadeIn();
    });
    $('.menu-icon').toggleClass("z-50 relative");
    $('.site-header').toggleClass("z-10 relative");
  });

  $('.button-slider-open').click(function(){
    $(document.body).toggleClass("slider-open");
    $('.slider-underlay').toggleClass("show-underlay");
    $('.case-wrapper').toggleClass("z-30");
    $('.menu-icon').toggleClass("z-0");
  });

  $('.js-slider-close').click(function(){
    $(document.body).toggleClass("slider-open");
    $('.slider-underlay').toggleClass("show-underlay");
    $('.menu-icon').toggleClass("z-0");
    $('.case-wrapper').toggleClass("z-30");
  });

  var glideCarousel = document.querySelector('.glide');

  if(glideCarousel) {
    // Generic content slider
    var glide = new Glide('.glide', {
      type: 'slider',
      perView: 4,
      gap: 50,
      breakpoints: {
        1024: {
          perView: 2,
          gap: 30
        },
        600: {
          perView: 1,
          gap: 0
        }
      }
    });

    glide.mount();
  }

  var newsCarousel = document.querySelector('.news-slider');

  if(newsCarousel) {
    // Latest news slider
    var news = new Glide('.news-slider', {
      type: 'carousel',
      perView: 3,
      arrows: true,
      gap: 50,
      peek: {
        before: 0,
        after: 125
      },
      breakpoints: {
        1024: {
          perView: 2,
          gap: 30
        },
        600: {
          perView: 1,
          gap: 15
        }
      },

    });

    news.mount();

  }

  // Flash carousel

  var flashCarousel = document.querySelector('.flash-carousel');

  if(flashCarousel) {

    var flash = new Glide('.flash-carousel', {
      type: 'carousel',
      perView: 1,
      gap: 0,
      autoplay: 600,
      hoverpause: false,
      animationDuration: 0,
    });

    flash.mount();

  }

  // Lazyload

  var myLazyLoad = new LazyLoad({
    elements_selector: ".lazyload",
  });
  // After your content has changed...
  myLazyLoad.update();
});


